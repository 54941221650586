.broker-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Dimmed background */
  }

.broker-settings-content{
    background-color: #1f1f1f;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 800px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #000;
}
  
  .broker-settings__details {
    background-color: #1e1e1e;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6);
    max-width: 800px;
    color: #fff;
  }
  
  .broker-settings__header {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .broker-settings__instructions {
    font-size: 0.95rem;
    line-height: 1.5;
    color: #bdbdbd;
    margin-bottom: 20px;
  }
  
  .broker-settings__link {
    color: #1e90ff;
    text-decoration: none;
    font-weight: 600;
  }
  
  .broker-settings__link:hover {
    text-decoration: underline;
  }
  
  .broker-settings__input {
    width: 100%;
    padding: 10px;
    margin: 15px 0;
    border-radius: 8px;
    border: 1px solid #444;
    background-color: #2a2a2a;
    color: #fff;
  }
  
  .broker-settings__button {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .broker-settings__button--save {
    background-color: #28a745;
    color: #fff;
  }
  
  .broker-settings__button--save:hover {
    background-color: #218838;
  }
  
  .broker-settings__button--cancel {
    background-color: #dc3545;
    color: #fff;
  }
  
  .broker-settings__button--cancel:hover {
    background-color: #c82333;
  }
  
.broker-settings-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

  .copiable-link {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .copiable-link__button {
    width: fit-content;
  }

  @media (max-width: 600px) {
    .broker-settings-content {
      width: 95%; /* Use most of the screen width */
      padding: 15px; /* Reduce padding for smaller screens */
      border-radius: 8px; /* Slightly smaller border radius */
    }
  
    .broker-settings__details {
      padding: 20px; /* Reduce padding */
    }
  
    .broker-settings__header {
      font-size: 1.5rem; /* Adjust font size */
      margin-bottom: 15px; /* Reduce margin */
    }
  
    .broker-settings__instructions {
      font-size: 0.85rem; /* Slightly smaller font size */
      line-height: 1.4; /* Adjust line height for better readability */
    }
  
    .broker-settings__input {
      padding: 8px; /* Reduce padding */
      margin: 10px 0; /* Adjust spacing */
    }
  
    .broker-settings__button {
      padding: 10px; /* Reduce button padding */
      font-size: 0.9rem; /* Slightly smaller font size */
    }
  
    .broker-settings-buttons {
      flex-direction: column; /* Stack buttons vertically */
      gap: 5px; /* Smaller gap between buttons */
    }
  
    .copiable-link {
      flex-direction: column; /* Stack elements vertically */
      gap: 5px; /* Adjust spacing */
    }
  
    .copiable-link__button {
      width: 100%; /* Make button full width */
    }
  }

  .broker-login-button{
    width: fit-content;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }