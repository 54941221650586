.modal {
    position: fixed;
    z-index: 1000; /* Ensure it appears above other content */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Modal content box */
  .modal-content {
    background-color: #1f1f1f;
    margin: auto;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Width of the modal box */
    max-width: 500px; /* Maximum width of the modal box */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Box shadow for a 3D effect */
  }
  
  /* Modal message */
  .modal-content p {
    margin: 0 0 20px; /* Margin for the paragraph */
  }
  
  /* Confirm and Cancel buttons */
  .modal-content button {
    padding: 10px 20px;
    margin: 0 10px;
    width: 100px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Confirm button style */
  .modal-content button:first-of-type {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  
  /* Cancel button style */
  .modal-content button:last-of-type {
    background-color: #f44336; /* Red */
    color: white;
  }
  