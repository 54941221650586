.user-config-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .user-config-modal-content {
    background-color: #1f1f1f;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #000;
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal label {
    display: block;
    margin-bottom: 10px;
  }
  
  .modal input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .user-config-modal-buttons {
    display: flex;
    width: auto;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .save-button,
  .close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-button-settings-modal {
    background-color: #28a745;
    color: #fff;
  }
  
  .close-button-settings-modal {
    background-color: #dc3545;
    color: #fff;
  }

  .config-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.config-table th,
.config-table td {
    border: 1px solid #333;
    padding: 10px;
    text-align: left;
}

.config-table th {
    background-color: #2c2c2c;
    color: #ffffff;
    text-transform: uppercase;
}

.config-table td {
    background-color: #1e1e1e;
}

.config-table tr:nth-child(even) td {
    background-color: #2a2a2a;
}

.config-table input[type="checkbox"] {
    transform: scale(1.2);
    cursor: pointer;
}

.config-table input[type="text"] {
    background-color: #2c2c2c;
    border: 1px solid #555;
    color: #f0f0f0;
    border-radius: 4px;
    padding: 5px;
    width: 90%;
    outline: none;
}
  
  @media (max-width: 768px) {
    .user-config-modal-content {
      width: 90%;
      padding: 15px;
    }
  
    .user-config-modal-buttons {
      flex-direction: column;
      align-items: stretch;
    }
  
    .save-button,
    .close-button {
      width: 100%;
      padding: 12px;
      font-size: 16px;
    }
  }
  
  @media (max-width: 480px) {
    .user-config-modal-content {
      width: 95%;
      padding: 10px;
    }
  
    .modal h2 {
      font-size: 18px;
    }
  
    .modal input[type="text"] {
      padding: 8px;
    }
  
    .save-button,
    .close-button {
      padding: 10px;
      font-size: 14px;
    }
  }