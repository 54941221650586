.locked-card-modern {
    width: 320px;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: center;
    margin: 20px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.locked-header-modern {
    background: linear-gradient(135deg, #6a0dad, #f06);
    color: #ffffff;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
}

.locked-content-modern {
    padding: 30px 20px;
    background: linear-gradient(135deg, #e2e2e2, #f9f9f9);
}

.lock-icon-modern {
    font-size: 40px;
    color: #6a0dad;
    margin-bottom: 10px;
}

.locked-text-modern {
    margin: 5px 0;
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.unlock-button-modern {
    background: linear-gradient(135deg, #6a0dad, #f06);
    color: #ffffff;
    border: none;
    padding: 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background 0.3s ease;
}

.unlock-button-modern:hover {
    background: linear-gradient(135deg, #5a0dad, #d05);
}

.unlocked-card-modern {
    background: linear-gradient(135deg, #6a0dad, #f06);
    color: #f0f0f0;
    padding: 20px;
    width: 500px;
    margin: 20px auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-family: 'Helvetica Neue', Arial, sans-serif;
}

.unlocked-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.stock-name {
    font-size: 1.6rem;
    color: #cfffc9;
    font-weight: bold;
}

.buy-info {
    display: flex;
    width: 40%;
    flex-direction: column;
    text-align: right;
}

.buy-text {
    font-size: 1.2rem;
    color: #28a745; /* Green for Buy Range */
    font-weight: bold;
}

.entity {
    font-size: 0.9rem;
    color: #ffdd57; /* Light yellow for description */
}

.line {
    height: 1px;
    background-color: #e0e0e0;
    margin: 15px 0;
}

.info-grid-card {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 10px;
}

.info-grid-card p {
    margin: 0;
}

.info-grid-card strong {
    display: block;
    font-size: 1.1rem;
    color: #33ff33; /* Color for highlighted info */
}

.call-info {
    margin-top: 15px;
    font-size: 0.9rem;
    color: #b6b6b6;
}

.call-info b {
    color: #f0f0f0; /* Lighter for emphasized text */
}

.exp-profit {
    color: #33ff33; /* Green for profit */
    font-weight: bold;
}

.stop-loss {
    color: #dc3545; /* Red for stop loss */
}

.target-price {
    color: #f39c12; /* Orange for target price */
}