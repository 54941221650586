.pay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #1f1f1f;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 400px;
  }
  
  .modal-content h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff;
  }
  
  .modal-content label {
    display: block;
    width: 300px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #ffffff;
  }