.add-subscriber-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #2a2a2a; /* Dark background */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 90%; /* Set a maximum height for the modal */
  overflow-y: auto; /* Enable vertical scrolling */
  color: #f0f0f0; /* Light text color for contrast */
  font-family: "Helvetica Neue", Arial; 
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
input[type="date"],
select {
  width: 100%;
  padding: 8px;
  margin-top: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.save-button,
.close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.save-button:hover,
.close-button:hover {
  background-color: #0056b3;
}