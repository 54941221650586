.expiry-dates-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background-color: #1f1f1f;
  color: #f0f0f0;
}

  .back-button-expiry-dates,
  .add-expiry-date-button {
    padding: 8px 16px;
    width: auto;
    font-size: 14px;
    margin-right: 5px;
    background-color: #e2472f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .back-button-expiry-dates:hover,
  .add-expiry-date-button:hover {
    background-color: #bc441f;
  }

  .expiry-dates-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    align-self: center;
  }

  .expiry-dates-table {
    border-collapse: collapse;
    width: 100%;
    max-height: 75vh; /* Adjust height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: auto; /* Enables horizontal scrolling if needed */
  }
  
  .expiry-dates-table th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #444;
    font-weight: bold;
    color: #ffffff;
    position: sticky; /* Keeps the header fixed */
    top: 0; /* Ensures it stays at the top of the scrolling container */
    z-index: 1; /* Keeps the header above the table rows */
  }
  
  .expiry-dates-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #2a2a2a;
    color: #f0f0f0;
  }
  
  
  .expiry-dates-table tbody tr:hover {
    background-color: #9e6161; /* Highlight row on hover */
  }
  
  .expiry-dates-table tbody td {
    vertical-align: middle;
  }
  
  .expiry-dates-table tbody tr:last-child td {
    border-bottom: none; /* Remove bottom border from last row */
  }
  
  .expiry-dates-table td.action-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Spacing between action buttons */
  }
  
  .expiry-dates-table tbody tr:nth-child(odd) td {
    background-color: #2a2a2a; /* Darker background for odd rows */
  }
  
  .expiry-dates-table tbody tr:nth-child(even) td {
    background-color: #2a2a2a; /* Lighter background for even rows */
  }