.Navbar {
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Helvetica Neue", Arial;
}

.Navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.Navbar li {
  margin: 0 15px;
}

.Navbar a {
  text-decoration: none;
  font-size: 1.25rem;
}

.Navbar a:hover {
  text-decoration: underline;
}

/* Button styles */
.Navbar-buttons {
  display: flex;
  align-items: center;
}

.login-button-navbar, .register-button-navbar, .eqyitywyn-algowyn-button-navbar {
  width: auto;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.login-button-navbar:hover, .register-button-navbar:hover {
  background-color: #bc441f;
}

/* Algowyn styles */
.algowyn-navbar {
  background-color: #1a1a1a; /* Dark background */
}

.algowyn-navbar a {
  color: #f0f0f0; /* Light text color */
}

.algowyn-navbar a:hover {
  color: #e2472f;
}

.algowyn-navbar .login-button-navbar, 
.algowyn-navbar .register-button-navbar, 
.algowyn-navbar .eqyitywyn-algowyn-button-navbar {
  background-color: #e2472f;
  color: #f0f0f0;
}