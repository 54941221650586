body{
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

.Login-container {
  margin-top: 50px;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #444; /* Slightly lighter border for contrast */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #0c0015, #05000b);
  color: #f0f0f0;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  text-align: center;
  color: #f0f0f0; 
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #f0f0f0; 
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #100f1c ; /* Dark background for input fields */
  border: 1px solid #444; /* Slightly lighter border for contrast */
  border-radius: 4px;
  color: #f0f0f0;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #bc441f;
}
