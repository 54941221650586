.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background-color: #1f1f1f;
  color: #f0f0f0;
}

.username-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.actions-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 10px;
}

.checkbox-selection {
  display: flex;
  align-items: center;
  gap: 10px;
}



.checkbox-selection input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #000;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
}

.checkbox-selection input[type="checkbox"]:checked {
  background-color: #bc441f;
  border-color: #bc441f;
}

.checkbox-selection input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 5px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.pnl-search-order-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.search-order-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-order-container input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  width: 250px; /* Adjust width as needed */
}

.search-order-container button.search-order-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.back-button-orders,
.start-stop-button,
.run-strategy-button,
.settings-button,
.register-user-button,
.monitor-button,
.modify-sl-wholesale-button,
.search-order-button,
.expiry-dates-button {
  padding: 8px 16px;
  width: auto;
  margin-bottom: 12px;
  font-size: 14px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button-orders {
  padding: 8px 16px;
  width: auto;
  margin-bottom: 12px;
  font-size: 14px;
  background-color: #028800;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-button-orders:hover {
  background-color: #0a5300;
}

.start {
  background-color: green;
}

.stop {
  background-color: red;
}

.start-stop-button:hover{
  background-color: #1f51bc;
}

.run-strategy-button:hover,
.monitor-button:hover {
  background-color: #bc441f;
}


.modify-sl-wholsale-button,
.modifyitarget-wholesale-button,
.exit-ws-button {
  padding: 8px 16px;
  width: auto;
  margin-bottom: 12px;
  font-size: 14px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modify-sl-wholsale-button:hover,
.modifyitarget-wholesale-button:hover,
.exit-ws-button:hover {
  background-color: #128800;
}


.logout-button {
  padding: 8px 16px;
  font-size: 14px;
  width: 100px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.date-selection,
.dropdown-selection,
.dropdown-selection-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-selection label,
.dropdown-selection label,
.dropdown-selection-filter label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.date-selection label input,
.dropdown-selection label select,
.dropdown-selection-filter label select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #f0f0f0;
}

.search-button {
  padding: 8px 16px;
  width: 100px;
  font-size: 14px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #bc441f;
}

.orders-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.orders-table table {
  width: 100%;
}

.orders-table th{
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #444;
  font-weight: bold;
  color: #ffffff;
  position: sticky; /* Keeps the header fixed */
  top: 0; /* Ensures it stays at the top of the scrolling container */
}

.orders-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #2a2a2a;
  color: #f0f0f0;
}

.orders-table th {
  background-color: #444;
  font-weight: bold;
}

.orders-table tbody tr:hover {
  background-color: #333;
}

.orders-table tbody td {
  vertical-align: middle;
}

.orders-table tbody tr:last-child td {
  border-bottom: none;
}

.orders-table td.action-buttons {
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.exit-button {
  padding: 5px 5px;
  font-size: 12px;
  background-color: #e40000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.exit-button:hover {
  background-color: #882121;
}

.modify-button,
.expand-button,
.orderbook-button {
  padding: 5px 5px;
  font-size: 12px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modify-button {
  background-color: #e2472f;
}

.modify-button:hover {
  background-color: #bc441f;
}

.expand-button {
  background-color: #e2472f;
}

.expand-button:hover {
  background-color: #bc441f;
}

.orderbook-button {
  background-color: #e2472f;
}

.orderbook-button:hover {
  background-color: #bc441f;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  width: 90%;
  max-width: 600px;
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 4px;
}

.popup-content h3 {
  margin-top: 0;
}

.popup-content button {
  display: block;
  margin-left: auto;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #bc441f;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
}

/* General page container styling */
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  background-color: #1f1f1f;
  color: #f0f0f0;
}

/* Username container for logout button */
.username-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Actions container for control buttons */
.actions-container {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .actions-container {
    flex-direction: row;
    position: static;
    justify-content: center;
  }

  .username-container {
    position: static;
    justify-content: center;
    margin-bottom: 10px;
  }

  .controls {
    flex-direction: row;
    gap: 15px;
  }

  .search-order-container input {
    width: 100%;
  }
}

/* Controls container for date and dropdown selections */
.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* Date and dropdown selection styling */
.date-selection,
.dropdown-selection,
.dropdown-selection-filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.date-selection label,
.dropdown-selection label,
.dropdown-selection-filter label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Search button and PnL container */
.search-button {
  padding: 8px 16px;
  width: 100px;
  font-size: 14px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.search-button:hover {
  background-color: #bc441f;
}

/* Responsive orders table styling */
.orders-table {
  width: auto;
  max-height: 75vh; /* Adjust height as needed */
  overflow-x: auto;
  margin-top: 20px;
  border-collapse: collapse;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.orders-table table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px; /* Ensures table doesn't shrink below readability */
  max-height: 75vh; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling if needed */
}

.orders-table th{
  width: fit-content;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #444444;
  color: #f0f0f0;
}

.orders-table td {
  width: fit-content;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #2a2a2a;
  color: #f0f0f0;
}

.modal-s {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.modal-content-s {
  text-align: center;
}

.modal-buttons-s {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.save-button-s {
  background: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button-s {
  background: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
}

.close-popup-button {
  background: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.close-popup-button:hover {
  background: #ff7875;
}

.modal-overlay-m {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-m {
  background: rgb(56, 51, 51);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(75, 70, 70, 0.2);
  text-align: center;
}

.modal-content-m h3 {
  margin-bottom: 20px;
  font-size: 24px;
}

.modal-content-m table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.modal-content-m table th,
.modal-content-m table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.modal-content-m table th {
  background-color: #3c3939;
  font-weight: bold;
}

.close-button-m {
  padding: 10px 20px;
  background-color: #2880eb;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button-m:hover {
  background-color: #1c5ab9;
}


/* Small screen adjustments */
@media (max-width: 576px) {
  .username-container, .actions-container {
    flex-direction: row;
    gap: 5px;
    align-items: center;
  }

  .controls {
    flex-direction: row;
    justify-content: space-around;
    gap: 10px;
  }

  .page-container {
    padding: 10px;
  }

  .orders-table table {
    width: fit-content;
    min-width: unset; /* Remove minimum width requirement for mobile */
    table-layout: auto; /* Allow columns to auto-adjust */
  }
}
