body{
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #2a2a2a; /* Dark background */
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  max-height: 90%; /* Set a maximum height for the modal */
  overflow-y: auto; /* Enable vertical scrolling */
  color: #f0f0f0; /* Light text color for contrast */
  font-family: "Helvetica Neue", Arial; 
}

.popup-content h2 {
  margin-top: 0;
  color: #f0f0f0; /* Light text color for contrast */
}

.popup-content table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.popup-content table td {
  padding: 8px;
  border: 1px solid #444; /* Darker border */
  background-color: #333; /* Dark background for table cells */
  color: #f0f0f0; /* Light text color for contrast */
}

.popup-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}