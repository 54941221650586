.color-code-container{
    background-color: aliceblue;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
}

.scoreboard-table {
    max-height: 75vh; /* Adjust height as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: auto; /* Enables horizontal scrolling if needed */
  }
  
  .scoreboard-table {
    border-collapse: collapse;
    width: 100%;
  }

  .scoreboard-table th {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #303030;
    font-weight: bold;
  
    /* Add these properties */
    position: sticky; /* Keeps the header fixed */
    top: 0; /* Ensures it stays at the top of the scrolling container */
    z-index: 1; /* Keeps the header above the table rows */
  }