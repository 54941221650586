.add-stock-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.add-stock-modal .modal-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #1f1f1f;
    color: #545353;
}


.add-stock-modal-content {
    background-color: #1f1f1f;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #000;
}

.add-stock-modal-content h2 {
    margin-top: 0;
    color: #f0f0f0;
}

.add-stock-modal-content label {
    display: block;
    margin-bottom: 10px;
    color: #f0f0f0;
    width: 300px;
}

.add-stock-modal-content input[type="text"],
.add-stock-modal-content input[type="date"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #333;
    color: #f0f0f0;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.save-button,
.close-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.save-button {
    background-color: #28a745;
    color: #fff;
}

.close-button {
    background-color: #dc3545;
    color: #fff;
}
.form-group {
    margin-bottom: 15px;
  }
  
.form-group-radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 5px;
  }
  
  .form-group-radio-item {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .form-group-radio-label {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 5px;
    color: #f0f0f0;
  }

  input[type="checkbox"] {
    width: 20px;
    margin-right: 10px;
  }