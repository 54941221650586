body {
    background-color: #0b0c10;
    position: relative;
}

body::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(15, 20, 25, 0.5), rgba(31, 40, 51, 0.5));
    pointer-events: none;
}

.action-buttons-equitywyn-equities {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-left: 20px;
    flex-wrap: wrap;
}

.back-button-equitywyn, .logout-button-equitywyn, .add-stock-button, .edit-stock-button, .all-calls-button, .watch-list-button, .algowyn-button, .stocks-dashboard-button, .high-momentumstocks-button {
    font-size: 14px;
    width: auto;
    min-width: 80px;
    background-color: #e2472f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px 12px;
}

.logout-button-equitywyn:hover, .add-stock-button:hover, .edit-stock-button:hover, .all-calls-button:hover, .watch-list-button:hover, .algowyn-button:hover {
    background-color: #bc441f;
}

.sort-options {
    display: flex;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    color: #f0f0f0;
    flex-direction: column;
}

.sort-options label {
    margin-right: 10px;
    font-weight: bold;
    color: #f0f0f0;
}

.sort-options select {
    width: 200px;
    background-color: #2b2b2b;
    color: #ffffff;
    border: 1px solid #444;
    border-radius: 5px;
    padding: 5px;
    font-size: 1rem;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
}

.equity-card {
    background: linear-gradient(135deg, #1f0037, #100026);
    border-radius: 10px;
    padding: 20px;
    width: 350px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #c5c6c7;
    border: #b6b6b6 solid 1px;
    font-family: "Helvetica Neue", Arial, sans-serif;
}

.equity-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
    background: linear-gradient(135deg, #24003f, #180039);
}

.equity-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.equity-card h3 {
    margin: 0;
    font-size: 1.5rem;
    font-weight: bold;
    color: #ff593f;
}

.equity-card .buy-text {
    color: #28a745;
    font-weight: bold;
    font-size: 1.25rem;
}

.equity-card .entity {
    color: #b6b6b6;
    font-size: 0.9rem;
}

.equity-card .line {
    border-bottom: 1px solid #ffffff;
    margin: 10px 0;
}

.equity-card .info-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    text-align: center;
}

.equity-card .info-grid p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #b6b6b6;
}

.equity-card .info-grid p strong {
    display: block;
    font-weight: bold;
    font-size: 1.25rem;
    color: #f0f0f0;
}

.blurred-card {
    filter: blur(5px);
    pointer-events: none;
}

.blurred-card .stock-name {
    filter: none;
    pointer-events: auto;
    z-index: 1;
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    padding: 0 5px;
}


@media (max-width: 768px) {
    .logout-button-equitywyn {
        font-size: 12px;
        width: 80px;
    }

    .sort-options {
        flex-direction: column;
        align-items: center;
    }

    .equity-card {
        width: 280px;
        padding: 15px;
    }

    .equity-card .info-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 578px) {
    .logout-button-equitywyn {
        width: 100%;
        font-size: 12px;
    }

    .action-buttons-equitywyn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 50px;
    }

    /*.action-buttons-equitywyn {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        margin-left: 20px;
        flex-wrap: wrap;
    }*/

    .card-container {
        gap: 10px;
    }

    .equity-card {
        width: 100%;
        padding: 10px;
    }

    .equity-card h3 {
        font-size: 1.25rem;
    }

    .equity-card .info-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
