body{
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f; /* Dark background */
}

.action-buttons-logs {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.back-button-logs {
  padding: 8px 16px;
  font-size: 14px;
  width: 100px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filters {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 20px;
}

.logs-table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .logs-table table {
    width: 100%;
  }
  
  .logs-table th,
  .logs-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #2a2a2a;
    color: #f0f0f0;
  }
  
  .logs-table th {
    background-color: #444;
    font-weight: bold;
  }
  
  .logs-table tbody tr:hover {
    background-color: #333;
  }
  
  .logs-table tbody td {
    vertical-align: middle;
  }
  
  .logs-table tbody tr:last-child td {
    border-bottom: none;
  }