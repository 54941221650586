/* HighMomentumStocks.css */

/* Container styling */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

/* Heading styling */
h2 {
    text-align: center;
    color: #ff593f;
    margin-bottom: 20px;
    /* color: #2c3e50; */
}

/* Filter section */
.filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 20px;
}

.filters label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
}

.filters select,
.filters input[type="date"],
.filters input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    background-color: #100f1c;
    border-radius: 4px;
    margin-top: 5px;
    font-size: 14px;
    width: 150px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background-color: #3498db; /* Calendar button color */
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
}

/* Table styling */
.high-momentum-stocks-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.high-momentum-stocks-table thead {
    background-color: #2c3e50;
    color: #fff;
}

.high-momentum-stocks-table thead th {
    padding: 10px;
    text-align: left;
    font-size: 14px;
}

.high-momentum-stocks-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.high-momentum-stocks-table tbody tr:hover {
    background-color: #f1f1f1;
}

.high-momentum-stocks-table tbody td {
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #ddd;
}

.high-momentum-stocks-table tbody tr:last-child td {
    border-bottom: none;
}

/* Responsive design */
@media (max-width: 768px) {
    .filters {
        flex-direction: column;
    }

    .filters label {
        width: 100%;
    }

    .filters select,
    .filters input[type="date"] {
        width: 100%;
    }

    table {
        font-size: 12px;
    }
}
/* Search box styling */
.search-box {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-left: auto;
    width: 150px;
}

.clear-button-highmomentum {
    padding: 8px 12px;
    background-color: #ff593f;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
}

.clear-button-highmomentum:hover {
    background-color: #e14e37;
}