body {
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

/* Section styles */
.home-section-aw, .strategies-section-aw, .faq-section-aw, .aboutus-section-aw {
  padding: 50px 20px;
  min-height: 960px;
  background-color: #1f1f1f;
  color: #f0f0f0;
  text-align: left;
}

#termsofuse {
  background-color: #1f1f1f;
  text-align: left;
  font-size: small;
}

.Home-container {
  text-align: center;
  font-size: 1.25rem;
  color: #f0f0f0;
  background-color: #1f1f1f;
}

.App-logo {
  height: 80px;
  margin-bottom: 20px;
  margin-top: 40px;
}

h1 {
  font-size: 3rem;
  color: #f0f0f0;
  margin-bottom: 20px;
}

h2t {
  font-size: 2.5rem;
  margin-left: 150px;
  color: #ff593f;
}

p {
  font-size: 1.25rem;
  margin-bottom: 40px;
  width: 80%;
  margin: 0 auto;
  color: #f0f0f0;
}

/* Strategies table styles */
.strategies-table {
  width: 80%;
  font-size: 1.25rem;
  margin: 0 auto;
  border-collapse: collapse;
  text-align: left;
  background-color: #ff593f;
  color: #f0f0f0;
}

.strategies-table th,
.strategies-table td {
  padding: 10px;
  border: 1px solid #ff593f;
}

.strategies-table th {
  background-color: #444;
  color: #f0f0f0;
}

.strategies-table tr:nth-child(even) {
  background-color: #333;
}

.strategies-table tr:nth-child(odd) {
  background-color: #2a2a2a;
}

/* FAQ styles */
.faq-container {
  margin: 20px 0;
  text-align: left;
  width: 80%;
  margin-left: auto; 
  margin-right: auto; 
  color: #f0f0f0;
}

.faq-item {
  margin-bottom: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  overflow: hidden;
  background-color: #2a2a2a;
}

.faq-question {
  padding: 15px;
  cursor: pointer;
  font-size: 1.25rem;
  background-color: #444;
  color: #f0f0f0;
}

.faq-answer {
  padding: 15px;
  background-color: #333;
  color: #f0f0f0;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .home-section-aw, .strategies-section-aw, .faq-section-aw, .aboutus-section-aw {
    padding: 80px 15px;
    min-height: auto;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2t {
    font-size: 2rem;
    margin-left: 20px;
  }

  .strategies-table {
    width: 90%;
    font-size: 1rem;
  }

  .App-logo {
    height: 60px;
  }

  p {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .home-section-aw, .strategies-section-aw, .faq-section-aw, .aboutus-section-aw {
    padding: 60px 10px;
  }

  .strategies-table {
    width: 100%;
    font-size: 0.9rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2t {
    font-size: 1.75rem;
    margin-left: 10px;
  }

  p {
    font-size: 1.25rem;
  }

  .faq-container, .strategies-table, .Home-container {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 578px) {
  .home-section-aw, .strategies-section-aw, .faq-section-aw, .aboutus-section-aw {
    padding: 80px 5px;
  }

  .action-buttons-home {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000; /* Ensures it stays above other elements */
    background-color: #1f1f1f;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for better visibility */
}


  .team {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2t {
    font-size: 1.5rem;
    margin-left: 5px;
  }

  .App-logo {
    height: 50px;
    margin-top: 20px;
  }

  p {
    font-size: 1.25rem;
  }

  .faq-question {
    font-size: 1rem;
  }

  .strategies-table {
    font-size: 0.8rem;
  }
}