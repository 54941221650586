body{
    font-family: "Helvetica Neue", Arial;
    background-color: #0b0c10;
  }
  
  .popup-HMSD {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup-content-HMSD {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 700px;
    max-height: 90%;
    overflow-y: auto;
    color: #f0f0f0;
    font-family: "Helvetica Neue", Arial; 
  }
  
  .popup-content-HMSD h2 {
    margin-top: 0;
    color: #f0f0f0;
  }
  
  .popup-content-HMSD table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .popup-content-HMSD table td {
    padding: 8px;
    border: 1px solid #444;
    background-color: #333;
    color: #f0f0f0;
  }
  
  .popup-content-HMSD button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-content-HMSD button:hover {
    background-color: #0056b3;
  }