.equity-wyn-all-calls-container {
  padding: 10px 20px 0px 20px;
  height: 100%;
  background-color: #0b0c10; /* Dark background color */
  color: #f0f0f0; /* Light text color */
}

.action-buttons-equitywyn-all-calls {
  display: flex;
  justify-content: flex-start;
}

.back-button-all-calls {
  font-size: 14px;
  width: 100px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button-all-calls:hover {
  background-color: #bc441f;
}

.details-button {
font-size: 14px; 
width: 100px;
background-color: #dd391f;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.details-button:hover{
background-color: #e04b35;
}

.equitywyn-title {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #ff593f; /* Title color */
  font-size: 28px;
  font-weight: bold;
  text-align: center; /* Centered text */
  margin-top: 20px;
}

.filters-all-calls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

/* Dropdown container adjustments */
.dropdown-selection,
.dropdown-selection-filter {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.date-selection label,
.dropdown-selection label,
.dropdown-selection-filter label {
  color: #f0f0f0;
  font-size: 16px;
  margin-bottom: 5px;
}

.date-selection input,
.dropdown-selection select,
.dropdown-selection-filter select {
  background-color: #2b2b2b; /* Sort options background */
  border: 1px solid #444; /* Border color */
  color: #ffffff; /* Text color */
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.selection-filters-all-calls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.equity-card {
  border-radius: 10px;
  padding: 20px;
  width: 370px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: #b6b6b6 solid 1px;
  color: #c5c6c7; /* Star White */
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.open-card {
  background: linear-gradient(to right, #1f0037, #100026); /* For open status */
}

.closed-card {
  background: linear-gradient(to right, #2d2d2d, #1a1a1a); /* For closed status */
}

.profit-card {
  background: linear-gradient(to right, #1b5528, #1a1a1a); /* For book profit status */
}

.loss-card {
  background: linear-gradient(to right, #6d2129, #1a1a1a); /* For stoploss-hit status */
}

.equity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5);
  background: linear-gradient(135deg, #24003f, #180039); /* Brightened gradient on hover */
}

.equity-card-header {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.equity-card-header h3 {
  font-size: 20px;
  color: #ff593f; /* Header text color */
}

.buy-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.buy-text {
  color: #28a745; /* Buy text color */
  font-weight: bold;
}

.entity {
  color: #b6b6b6; /* Entity text color */
  font-size: 14px;
}

.line {
  height: 2px;
  background-color: #444;
  margin: 10px 0;
}

.info-grid {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.info-grid p {
  font-size: 14px;
  color: #f0f0f0; /* Info grid text color */
}

.info-grid strong {
  color: #ff593f; /* Highlighted text */
}

.time-frame {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

.stock-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 0 auto;
}

.stock-card-header {
  display: flex;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}

.stock-card-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.stock-detail {
  flex: 1 1 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
}

.label {
  font-size: 16px;
  color: #434343;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.horizon-buy {
  display: flex;
  align-items: center;
}

.horizon-buy > div {
  margin-right: 16px; /* Spacing between the Horizon details and the button */
}

.all-calls-table {
  max-height: 75vh; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: auto; /* Enables horizontal scrolling if needed */
}



.all-calls-table {
  border-collapse: collapse;
  width: 100%;
}

.all-calls-table th {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #444;
  font-weight: bold;
  color: #ffffff;
  position: sticky; /* Keeps the header fixed */
  top: 0; /* Ensures it stays at the top of the scrolling container */
  z-index: 1; /* Keeps the header above the table rows */
}

.all-calls-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #2a2a2a;
  color: #f0f0f0;
}


.all-calls-table tbody tr:hover {
  background-color: #9e6161; /* Highlight row on hover */
}

.all-calls-table tbody td {
  vertical-align: middle;
}

.all-calls-table tbody tr:last-child td {
  border-bottom: none; /* Remove bottom border from last row */
}

.all-calls-table td.action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Spacing between action buttons */
}

.all-calls-table tbody tr:nth-child(odd) td {
  background-color: #2a2a2a; /* Darker background for odd rows */
}

.all-calls-table tbody tr:nth-child(even) td {
  background-color: #2a2a2a; /* Lighter background for even rows */
}
.clear-filter-all-calls {
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  margin-left: 20px;
  margin-top: 20px;
}

/* Mobile Specific Styles */
@media (max-width: 578px) {
  .all-calls-table {
    width: 100%;
  }

  .all-calls-table table {
    min-width: 800px;
    min-width: unset;
    table-layout: fixed;
  }

  .all-calls-table td {
    padding: 10px 5px;
    font-size: 1rem;
    white-space: nowrap;          /* Prevent text from wrapping to the next line */
    overflow: hidden;             /* Hide overflow content */
    text-overflow: ellipsis;      /* Show ellipsis (…) for overflow content */
    max-width: 150px;             /* Set a maximum width for the column (adjust as needed) */
    word-break: break-all;        /* Break long words to avoid overflow */
  }


  .equitywyn-title {
    font-size: 22px;
    /* Adjust title font size on mobile */
    margin-top: 20px;
  }

  .selection-filters-all-calls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .filters-all-calls {
    flex-direction: column;
    /* Stack filter options vertically on mobile */
    align-items: center;
    gap: 15px;
    /* Adjust gap between filters */
  }

  .dropdown-selection-filter {
    display: flex;
    flex-direction: row;
    width: 100px;
  }

  .dropdown-selection-filter label {
    width: 100px;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    color: #f0f0f0;
  }

  .date-selection {
    display: flex;
    flex-direction: row;
    /* Stack labels and inputs vertically */
    align-items: center;
    /* Center the inputs */
    margin-bottom: 5px;
    /* Space below the date selection */
  }

  .date-selection label {
    font-size: 14px;
    /* Adjust font size for mobile */
    margin-bottom: 5px;
    /* Small gap between label and input */
  }

  .date-selection input {
    width: 80%;
    /* Make inputs take up most of the screen width */
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    background-color: #2b2b2b;
    border: 1px solid #444;
    color: #ffffff;
  }
}
