.action-buttons-subscription {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
  margin-top: 20px;
}

.back-button-subscription, .add-subscriber-button {
  padding: 8px 16px;
  font-size: 14px;
  width: auto;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-button-subscription {
  padding: 8px 16px;
  font-size: 14px;
  width: 100px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pay-button-subscription:hover {
  background-color: #45a045;
}

.subscription-table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .subscription-table table {
    width: 100%;
  }
  
  .subscription-table th,
  .subscription-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #2a2a2a;
    color: #f0f0f0;
  }
  
  .subscription-table th {
    background-color: #444;
    font-weight: bold;
  }
  
  .subscription-table tbody tr:hover {
    background-color: #333;
  }
  
  .subscription-table tbody td {
    vertical-align: middle;
  }
  
  .subscription-table tbody tr:last-child td {
    border-bottom: none;
  }

  .action-buttons-subscription {
    display: flex;
    align-items: center;
    gap: 10px;
}