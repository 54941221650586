body {
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

.message-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: top;
}

.message-modal-content {
  width: 600px;
  height: fit-content;
  align-items: start;
  background: #222;
  padding: 20px;
  border-radius: 5px;
  margin-top: 300px;
  text-align: left; /* Ensures the text is left-aligned */
}

.button-container {
  text-align: center; /* Align the button in the center */
}

.message-modal-content button {
  width: 100px;
  margin-top: 10px;
  padding: 5px 10px;
}

  