/* ExpandedOrderBookModal.css */

.order-book-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px; /* Add padding to avoid cutting on the sides */
  overflow-y: auto; /* Enables scrolling if modal height exceeds viewport */
}

.modal-content {
  background: #2a2a2a; /* Dark background */
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh; /* Ensures modal does not exceed viewport height */
  overflow-y: auto; /* Enables internal scrolling if content overflows */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5); /* Darker shadow */
  position: relative;
  color: #f0f0f0; /* Light text color for contrast */
  font-family: "Helvetica Neue", Arial;
  margin: 0 auto; /* Center align */
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: #f0f0f0; /* Light text color for contrast */
}

.order-book-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-book-table th,
.order-book-table td {
  border: 1px solid #444; /* Darker border */
  padding: 8px;
  text-align: left;
  background-color: #333; /* Dark background for table cells */
  color: #f0f0f0; /* Light text color for contrast */
}

.order-book-table th {
  background-color: #555; /* Slightly lighter background for headers */
}

.order-book-table tbody tr:nth-child(even) {
  background-color: #3a3a3a; /* Alternating row color */
}

.order-book-table tbody tr:hover {
  background-color: #444; /* Hover row color */
}

/* Wrapper for centering the button */
.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px; /* Optional: space above the button */
}

.orderbook-modal-content button {
  width: auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.orderbook-modal-content button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  .order-book-table th,
  .order-book-table td {
    padding: 6px;
    font-size: 14px;
  }

  .modal-content button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 100%;
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 18px;
  }

  .order-book-table th,
  .order-book-table td {
    font-size: 12px;
    padding: 4px;
  }

  .modal-content button {
    padding: 6px 12px;
    font-size: 12px;
  }
}