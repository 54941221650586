/* Global Styles */
body {
    background-color: #0b0c10; /* Dark background for the whole page */
    color: #ffffff; /* Light text for the whole page */
    margin: 0;
    font-family: "Arial", sans-serif;
  }
  
  /* Card Styling */
  .card {
    border: 1px solid #333333; /* Subtle border for cards */
    border-radius: 10px;
  }
  
  /* Card Header Styling */
  .card-header {
    font-weight: bold;
  }
  
  /* Buttons */
  .btn-primary {
    background-color: #007bff; /* Bootstrap primary button color */
    border: none;
  }
  
  .btn-danger {
    background-color: #dc3545; /* Bootstrap danger button color */
    border: none;
  }
  
  .btn-outline-secondary {
    border-color: #6c757d; /* Border for secondary button */
    color: #6c757d;
  }
  
  /* List Group Items */
  .list-group-item {
    background-color: #0b0c10; /* Dark list items */
    color: #ffffff; /* Light text */
    border: 1px solid #333333;
  }
  
  
  /* Personal Info Section */
  .card .card-body p {
    margin: 0;
    font-size: 1rem;
  }
  
  .card .card-body p strong {
    color: #ff5722; /* Highlight important text (like email, phone, etc.) */
  }
  
  /* Profile Header */
  .card-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  .card-body button{
    width: 100%;
    margin-top: 10px;
    font-size: 1rem;
  }
  
  /* Container */
  .container {
    padding: 2rem;
    border-radius: 10px;
  }
  
  /* Responsiveness */
  @media (max-width: 768px) {
    .card-header {
      font-size: 1.2rem;
    }
  
    .btn {
      font-size: 0.9rem;
    }
  
    img.rounded-circle {
      width: 120px;
      height: 120px;
    }
  }  