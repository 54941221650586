.disclaimer-container {
    background-color: #0b0c10; /* Dark background */
    color: #f0f0f0; /* Light text */
    font-family: 'Helvetica Neue', Arial, sans-serif;
    padding: 20px;
    border-radius: 10px;
  }
  
  .disclaimer-content {
    max-width: 1080px;
    margin: 0 auto;
    background: linear-gradient(135deg, rgba(31, 40, 51, 0.5), rgba(15, 20, 25, 0.5));
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  }
  
  h1 {
    color: #ff593f; /* Accent color for header */
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  h3 {
    color: #c5c6c7; /* Lighter text for sub-headers */
    margin-top: 30px;
    font-size: 1.5rem;
  }

  p {
    color: #c5c6c7; /* Light grey for paragraphs */
    line-height: 1.6; /* Improve readability */
    width: fit-content;
    margin: 15px 20px;
    font-size: 1rem; /* Slightly larger text for better readability */
  }
  
  p strong {
    color: #ff593f; /* Accent color for strong/bold text */
  }
    
  ol {
    margin-left: 20px;
    padding-left: 15px;
    list-style: lower-alpha;
  }
  
  ol li {
    margin-bottom: 10px;
    color: #b6b6b6; /* Lighter grey for list items */
  }
  
  li::marker {
    color: #ff593f; /* Accent color for markers */
  }
  
  @media (max-width: 768px) {
    .disclaimer-content {
      padding: 20px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h3 {
      font-size: 1.25rem;
    }
  } 

.disclaimer-close-button{
  width: 100px;
}