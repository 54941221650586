/* Modal background */
.add-stock-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Modal content box */
.modal-content {
    background-color: #1f1f1f; /* Dark background for modal */
    padding: 20px;
    border-radius: 8px;
    width: 80%; /* Adjust width as needed */
    max-width: 1080px;
    color: #f0f0f0; /* Light text color */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    overflow-y: auto; /* Allow scrolling if content overflows */
}

/* Title inside modal */
.modal-content h2 {
    margin-bottom: 20px;
}

/* Labels and inputs for the modal form */
.modal-content label {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    flex: 1;
}

.modal-content select,
.modal-content input {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #444; /* Dark border */
    border-radius: 4px;
    background-color: #2d2d2d; /* Dark input background */
    color: #f0f0f0; /* Light text color */
}

/* Form row for the modal form */
.form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    flex-wrap: wrap; /* Allow form fields to wrap on smaller screens */
}

.form-row label {
    flex: 1;
    margin-right: 10px;
    min-width: 120px; /* Prevent label from being too small */
}

.form-row label:last-child {
    margin-right: 0;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

/* Modal buttons styles */
.save-button,
.close-button {
    padding: 10px 20px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    background-color: #ff593f; /* Button color */
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.save-button:hover,
.close-button:hover {
    background-color: #e04b35; /* Hover color */
}

/* Responsive styling for mobile and smaller screens */
@media (max-width: 768px) {
    .modal-content {
        width: 90%; /* Make the modal content slightly wider for mobile */
        max-width: 95%; /* Ensure it fits within screen on very small devices */
        padding: 15px; /* Reduce padding for mobile */
    }

    .form-row {
        flex-direction: column; /* Stack form elements vertically on small screens */
        gap: 10px; /* Add space between form fields */
    }

    .form-row label {
        margin-right: 0; /* Remove margin between labels in mobile view */
        min-width: 100%; /* Allow labels to stretch fully */
    }

    .modal-buttons {
        flex-direction: column; /* Stack buttons vertically */
        align-items: center; /* Center buttons */
    }

    .save-button,
    .close-button {
        width: 100%; /* Make buttons take full width on mobile */
        margin-left: 0; /* Remove left margin */
        margin-bottom: 10px; /* Add spacing between buttons */
    }
}
