
/* Base Styles */
.equity-wyn-container {
  background-color: #060010;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #f0f0f0;
  padding: 20px;
}

.header {
  color: #ff593f;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.equity-wyn-content {
  display: flex;
}

.sidebar {
  background: linear-gradient(135deg, #161324, #100f1c);
  border-radius: 10px;
  padding: 20px;
  margin-right: 20px;
  flex-basis: 25%;
  width: 100%;
  max-width: 350px;
  height: fit-content;
}

.logo-container {
  margin-bottom: 20px;
  text-align: center;
}

.logo {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 10px;
}

.company-name {
  font-size: 1.25rem;
}

.table-header {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

.stock-details-table {
  width: 100%;
  border-spacing: 0;
}

.stock-details-table tr {
  border-bottom: 1px solid #444;
}

.stock-details-table td {
  padding: 10px 5px;
  font-size: 1rem;
}

.main-content {
  flex-basis: 75%;
  width: 100%;
}

.writeup-container {
  background: linear-gradient(135deg, #161324, #100f1c);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.writeup-container h3 {
  color: #ff715b;
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.writeup-container h5 {
  color: #ff715b;
  margin-bottom: 15px;
  margin-left: 80px;
}

.writeup-container p {
  font-size: 1rem;
  line-height: 1.6;
}

.section {
  margin-bottom: 20px;
}

hr {
  border: 0;
  height: 2px;
  background: #ff593f;
  margin: 20px 0;
}

.action-buttons-equitywyn-stock-details {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
}

.back-button-equitywyn-sd {
  font-size: 14px;
  width: 80px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 12px;
}

/* Media Queries for Responsiveness */

/* Tablet adjustments */
@media (max-width: 768px) {
  .header {
    font-size: 2rem;
  }

  .equity-wyn-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .sidebar {
    margin-right: 0;
    flex-basis: 100%;
    max-width: none;
  }

  .main-content {
    flex-basis: 100%;
  }

  .writeup-container h3 {
    font-size: 1.25rem;
  }

  .writeup-container h5 {
    margin-left: 50px;
  }

  .back-button-equitywyn-sd {
    width: 80px;
    font-size: 13px;
  }
}

/* Mobile adjustments */
@media (max-width: 578px) {
  .header {
    font-size: 1.75rem;
  }

  .equity-wyn-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .sidebar {
    margin-right: 0;
    width: 100%;
    padding: 15px;
  }

  .company-name {
    font-size: 1rem;
  }

  .stock-details-table td {
    font-size: 0.9rem;
    padding: 8px 3px;
  }

  .writeup-container h3, .writeup-container h5 {
    font-size: 1.1rem;
    margin-left: 20px;
  }

  .back-button-equitywyn-sd {
    font-size: 12px;
  }
}
