body {
  font-family: "Helvetica Neue", Arial;
}
.registration-container {
  margin-top: 50px;
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #444; /* Slightly lighter border for contrast */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(135deg, #0c0015, #05000b);
  color: #f0f0f0; /* Light text color for contrast */
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #f0f0f0; /* Light text color for contrast */
}

h2 {
  text-align: center;
  color: #f0f0f0; /* Light text color for contrast */
}

.form-group {
  margin-bottom: 15px;
}

input, select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background-color: #100f1c; /* Dark background for input fields */
  border: 1px solid #444; /* Slightly lighter border for contrast */
  border-radius: 4px;
  color: #f0f0f0; /* Light text color for contrast */
}

button {
  width: 100%;
  padding: 10px;
  background-color: #e2472f;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group-radio {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5px;
}

.form-group-radio-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-group-radio-label {
  width: 100%;
  align-items: flex-start;
  margin-bottom: 5px;
  color: #f0f0f0; /* Light text color for contrast */
}

input[type="checkbox"] {
  width: 20px;
  margin-right: 10px;
}

button:hover {
  background-color: #bc441f;
}