body{
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

.App-logo {
    height: 15vmin;
    pointer-events: none;
  }

  h2 {
    text-align: center;
  }

  .run-algo-button {
    position: absolute;
    width: 100px;
    right: 20px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }