/* Make the entire page a flexbox to handle sticky footer */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  #dashboard-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* 100% of the viewport height */
  }
  
  .content-wrap {
    flex: 1; /* This allows the content to grow and push the footer down */
  }
  
  /* Optional footer styling to align it properly */
  footer {
    background-color: #343a40;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  
  .welcome-section {
      background-color: #0b0c10;
      color: white;
      padding: 60px 0;
    }
    
    .welcome-section h1 {
      font-size: 2.5rem;
      font-weight: bold;
    }
    
    .welcome-section p {
      font-size: 1.2rem;
      margin-top: 10px;
    }
    
    .card {
      border-radius: 8px;
    }
    
    .card-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
    
    .card-text {
      font-size: 1rem;
    }