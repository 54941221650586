/* General Styles */
body {
    font-family: "Helvetica Neue", Arial;
    background-color: #1f1f1f; /* Dark background */
}

h4 {
    text-align: left;
    color: #ff593f; /* Light text color for contrast */
}

.action-buttons-monitor {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; /* Wrap buttons on smaller screens */
}

.monitor-table-action-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.start-stop-button-monitor, .orderbook-button-monitor, .otp-button-monitor,
.back-button-monitor, .refresh-button-monitor, .logs-button-monitor, .subscription-button-monitor {
    padding: 8px 16px;
    font-size: 14px;
    width: 100px;
    background-color: #e2472f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.start-stop-algo-button-monitor {
    padding: 8px 16px;
    font-size: 14px;
    width: 130px;
    background-color: #e2472f;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.start-button {
    background-color: green;
    color: white;
}

.stop-button {
    background-color: red;
    color: white;
}

/* Container Styles */
.monitor-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    background-color: #1f1f1f;
    color: #f0f0f0;
}

.user-info-table, .job-info-table table {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling */
    margin-bottom: 20px; /* Space between tables */
    border-color: #e4e4e4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-info-table th,
.user-info-table td,
.job-info-table th,
.job-info-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #444;
    background-color: #2a2a2a;
    color: #f0f0f0;
}

.user-info-table th, .job-info-table th {
    background-color: #444;
    font-weight: bold;
}

.job-info-table {
    width: 50%;
    border-collapse: collapse;
}

.job-info-table tbody tr:hover {
    background-color: #333;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .job-info-table {
        width: 100%; /* Full width on medium screens */
    }

    .start-stop-button-monitor, .orderbook-button-monitor, .otp-button-monitor,
    .back-button-monitor, .refresh-button-monitor, .logs-button-monitor, .subscription-button-monitor {
        width: 90px; /* Slightly smaller buttons on medium screens */
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .monitor-container {
        padding: 15px;
    }

    .action-buttons-monitor {
        flex-direction: row;
        gap: 8px; /* Reduce gap between buttons */
    }

    .start-stop-button-monitor, .orderbook-button-monitor, .otp-button-monitor,
    .back-button-monitor, .refresh-button-monitor, .logs-button-monitor, .subscription-button-monitor,
    .start-stop-algo-button-monitor {
        width: auto; /* Full-width buttons on smaller screens */
        padding: 8px 12px;
        font-size: 12px;
    }

    h4 {
        font-size: 18px; /* Smaller header on smaller screens */
    }

    .job-info-table, .user-info-table {
        font-size: 14px; /* Smaller font size for tables */
    }
}

@media (max-width: 480px) {
    .monitor-container {
        padding: 10px;
    }

    .settings-modal-buttons {
        flex-direction: column; /* Stack buttons vertically */
        gap: 6px;
    }

    .start-stop-button-monitor, .orderbook-button-monitor, .otp-button-monitor,
    .back-button-monitor, .refresh-button-monitor, .logs-button-monitor, .subscription-button-monitor,
    .start-stop-algo-button-monitor {
        width: auto; /* Full width on very small screens */
        font-size: 12px;
    }

    .user-info-table th, .user-info-table td,
    .job-info-table th, .job-info-table td {
        padding: 6px; /* Reduce padding for smaller screens */
        font-size: 12px; /* Smaller font size for table content */
    }
}
