/* General container styling */
.equity-wyn-container {
  padding: 10px 20px;
  background-color: #0b0c10;
  color: #f0f0f0;
  border-radius: 10px;
  max-width: 100%;
  margin: 0 auto;
}

/* Title styling */
.equitywyn-title {
  text-align: center;
  color: #ff593f;
  font-size: 24px;
  font-weight: bold;
  margin-top: 5px;
}

/* Back button styling */
.back-button-watch-list {
  width: 100px;
  background-color: #2d0633;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button-watch-list:hover {
  background-color: #1f0026;
}

/* Action buttons container */
.action-buttons-equitywyn-watch-list {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

/* Watchlist table container for responsive scrolling */
.watch-list-table {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scroll on smaller screens */
}

.watch-list-table table {
  width: 100%;
  border-collapse: collapse;
}

.watch-list-table th,
.watch-list-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #444;
  background-color: #2a2a2a;
  color: #f0f0f0;
}

.watch-list-table th {
  background-color: #444;
  font-weight: bold;
  text-transform: uppercase;
}

.watch-list-table tbody tr:hover {
  background-color: #333;
}

.watch-list-table tbody td {
  vertical-align: middle;
}

/* Action buttons styling inside table */
.action-buttons {
  display: flex;
  gap: 10px;
}

/* Edit and delete buttons styling */
.edit-button,
.delete-button {
  background-color: #e2472f;
  color: #f0f0f0;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #bc441f;
}

.delete-button:hover {
  background-color: #e30000;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .equitywyn-title {
      font-size: 20px;
  }

  .back-button-watch-list {
    width: 100px;
    background-color: #2d0633;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;;
  }

  .watch-list-table th,
  .watch-list-table td {
      font-size: 14px;
      padding: 10px;
  }

  /* Make table more compact on smaller screens */
  .action-buttons-equitywyn-watch-list {
      flex-direction: column;
      align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .equitywyn-title {
      font-size: 18px;
  }

  .back-button-watch-list {
    width: 100px;
    background-color: #2d0633;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .watch-list-table th,
  .watch-list-table td {
      font-size: 12px;
      padding: 8px;
  }

  /* Stack action buttons vertically */
  .action-buttons {
      flex-direction: column;
      align-items: center;
      gap: 5px;
  }
}
