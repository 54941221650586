body{
  font-family: "Helvetica Neue", Arial;
  background-color: #1f1f1f;
}

.modal-overlay-exit-ws {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: #222;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    color: #f0f0f0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h2 {
    margin-top: 0;
  }
  
  .modal-content label {
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-content input,
  .modal-content select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #555;
    border-radius: 5px;
    background: #333;
    color: #f0f0f0;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .modal-buttons button:first-child {
    background: #28a745;
    color: #fff;
  }
  
  .modal-buttons button:last-child {
    background: #dc3545;
    color: #fff;
  }
  
  .popup-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #444;
    color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }